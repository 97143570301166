import {
    defaultActions,
    defaultSequence,
} from '@24i/nxg-sdk-smartott-defaults/src/clients/AppStartClient';
import React, { createContext, useContext, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useRouter, WebRouter } from '@24i/nxg-core-router/src/NextRouter';
import { useAppSettingsData } from '@24i/nxg-sdk-smartott-shared/src/context/AppSettingsData';
import { Storage } from '@24i/nxg-sdk-quantum';
import { useCurrentWebPageConfig } from '../../hooks/useCurrentWebPageConfig';
import { useStore } from '../ApplicationStore';
import { ApplicationStates } from './constants';
import { AppStartContextTypeWeb, AppStartProviderProps } from './types';

export * from './constants';

const AppStartContext = createContext<AppStartContextTypeWeb>({} as AppStartContextTypeWeb);

let appInitialized = false;
const SERVICE_THEME_ID = 'SERVICE_THEME_ID';
const SERVICE_DEFAULT_THEME_ID = 'SERVICE_DEFAULT_THEME_ID';

export const AppStartProvider = ({
    client,
    sequence = defaultSequence,
    actions = defaultActions,
    children,
}: AppStartProviderProps) => {
    const queryClient = useQueryClient();
    const [appState, setAppState] = useState(ApplicationStates.notInitialized);
    const { client: appSettingsDataClient } = useAppSettingsData();
    const store = useStore();
    const webRouter = useRouter();
    const initialWebUrl = useRef<{ href: string; as: string } | null | undefined>();
    const { currentPageConfig } = useCurrentWebPageConfig();
    const { t } = useTranslation();

    const initApplication = async () => {
        if (!appInitialized) {
            const themeId = await Storage.getItem(SERVICE_THEME_ID);
            const defaultThemeId = await Storage.getItem(SERVICE_DEFAULT_THEME_ID);
            const serviceConfig = await appSettingsDataClient.fetchServiceConfig();
            if (!defaultThemeId && serviceConfig?.features?.themes?.defaultThemeId) {
                Storage.setItem(
                    SERVICE_DEFAULT_THEME_ID,
                    serviceConfig?.features?.themes?.defaultThemeId
                );
            }
            if (!themeId && serviceConfig?.features?.themes?.defaultThemeId) {
                Storage.setItem(SERVICE_THEME_ID, serviceConfig?.features?.themes?.defaultThemeId);
            } else if (
                themeId &&
                defaultThemeId &&
                serviceConfig?.features?.themes?.defaultThemeId &&
                serviceConfig?.features?.themes?.defaultThemeId !== defaultThemeId
            ) {
                Storage.setItem(SERVICE_THEME_ID, serviceConfig?.features?.themes?.defaultThemeId);
                Storage.setItem(
                    SERVICE_DEFAULT_THEME_ID,
                    serviceConfig?.features?.themes?.defaultThemeId
                );
            }
            await client.initApplication(queryClient, t, webRouter.query || {});
            appInitialized = true;
            setAppState(ApplicationStates.initialized);
        }
    };

    const getInitialWebRoute = (router?: WebRouter): { href: string; as: string } | null => {
        if (!router) return null;
        const href = router.pathname || '';
        return { href, as: router.asPath };
    };

    // Sets initial web route that is used to route the application to the same page
    // that user entered as url when all navigation steps are done
    const setInitialWebRoute = (router?: WebRouter) => {
        if (router && initialWebUrl.current === undefined) {
            initialWebUrl.current = getInitialWebRoute(router);
        }
    };

    // Do not use this unless you absolutely need to and know what you are doing, this
    // serves mainly for the 404 workaround issue for next and shouldn't really be used otherwise.
    const dangerouslyOverrideInitialWebRoute = (route: { href: string; as: string }) => {
        initialWebUrl.current = route;
    };

    const runStartSequence = async (navigation?: any, router?: WebRouter) => {
        setInitialWebRoute(router);

        if (appState === ApplicationStates.notInitialized) {
            // due to apps, where login and redirection goes from external websites
            await initApplication();
        }

        const lastStep = sequence[sequence.length - 1];

        // eslint-disable-next-line no-restricted-syntax
        for (const step of sequence) {
            try {
                // eslint-disable-next-line no-await-in-loop
                const result = await client[step](queryClient, store, appState, currentPageConfig);

                if (result) {
                    actions[step](
                        undefined,
                        router,
                        initialWebUrl.current,
                        typeof result === 'object' ? result : undefined // only interested in sending params if result != boolean
                    );

                    if (step === lastStep) {
                        initialWebUrl.current = null;
                    }

                    // If and action is required, we need to break out of the loop
                    // so the sequence can be restarted after the action is done.
                    break;
                }
            } catch (e) {
                console.error(e);
            }
        }

        setAppState(ApplicationStates.initialNavigationComplete);
    };

    const skipStartSequence = () => {
        if (appState === ApplicationStates.notInitialized) {
            throw new Error(
                'cannot run start sequence before calling initApplication. Please call initApplication before you run start sequence'
            );
        }
        setAppState(ApplicationStates.initialNavigationComplete);
    };

    return (
        <AppStartContext.Provider
            value={{
                skipStartSequence,
                runStartSequence,
                initApplication,
                appState,
                onLogout: client.onLogout,
                dangerouslyOverrideInitialWebRoute,
            }}
        >
            {children}
        </AppStartContext.Provider>
    );
};

export const useAppStart = () => useContext(AppStartContext);
