import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { isPlatformWeb } from 'renative';
import {
    ASSET_TYPE,
    EPG_TYPE,
    Rating,
    isMovie,
    isSeries,
    isEpisode,
    isBroadcast,
    isChannel,
    isLiveEvent,
    getIsPodcastSeries,
    RECORDING_STATUS,
    BLOCKING_REASON_TYPES,
} from '@24i/nxg-sdk-photon/src';
import { Share } from 'react-native';
import { log } from '@24i/nxg-core-utils/src/logger';
import { usePlayerData } from '@24i/nxg-sdk-smartott-shared/src/context/PlayerData';
import { useRefreshOnFocus } from '@24i/nxg-sdk-smartott-shared/src/hooks/useRefreshOnFocus';
import { showToast } from '@24i/nxg-sdk-gluons/src/components/ui/Toast';
import useAssetActions from '../../../../../hooks/useAssetActions';
import { useStore } from '../../../../../context/ApplicationStore';
import { getRuntimeConfig } from '../../../../../Application/initApp';
import { brodacastStartsInFiveMinsOrLess } from '../../../../../utils/reminderUtils';
import { useAppConfigQuery } from '../../../../../hooks/query/useAppConfigQuery';
import { useAssetRatingQuery, useAssetUpdateRatingQuery } from '../../../../../hooks/query/asset';
import { DetailContentViewProps, DetailsContentProps, PrimaryButtonType } from '../types';
import useAssetBlockersValidation from '../../../../../hooks/useAssetBlockersValidation';
import { getEpgType } from '../../../viewModel/utils';

const useViewModel = (props: DetailsContentProps): DetailContentViewProps => {
    const {
        asset,
        seasons,
        recordingState,
        fetchReminder,
        onMyListPress,
        onRecordPress,
        onRemindPress,
    } = props;

    const { selectedProfile, userData } = useStore();
    const { appSettings } = useAppConfigQuery();
    const screens = getRuntimeConfig('screens');
    const { assetHasPlaybackAction } = usePlayerData();
    const { assetHasProgress, getEpisodeToWatch } = useAssetActions();
    const { t } = useTranslation();
    const { data: assetRating, isFetching: fetchingRatingInfo } = useAssetRatingQuery(asset);
    const { updateUserRating, deleteUserRating } = useAssetUpdateRatingQuery(asset);
    const { fetchAssetBlockers } = useAssetBlockersValidation({});

    const ratingConfig = {
        enabled: appSettings?.features.ratings?.enabled,
        rateableAssets: appSettings?.features.ratings?.rateableAssets,
    };
    const remindersEnabled = appSettings?.features.reminders?.enabled;
    const recordingConfig = {
        enabled: appSettings?.features.recordings?.enabled,
    };

    const [isRatingModalVisible, setRatingModalVisible] = useState<boolean>(false);
    const [isShareDropdownVisible, setisShareDropdownVisible] = useState<boolean>(false);
    const [primaryButtonType, setPrimaryButtonType] = useState(PrimaryButtonType.LOADING);

    const hasPlayableAction = assetHasPlaybackAction(asset, getEpgType(asset));
    const isRateableAsset = Boolean(
        ratingConfig.rateableAssets?.includes(asset.type) &&
            !isLiveEvent(asset) &&
            asset.type !== ASSET_TYPE.EPISODE
    );

    const isRecordableAsset = Boolean(
        !!userData &&
            asset.type === ASSET_TYPE.BROADCAST &&
            asset.channelId &&
            !!onRecordPress &&
            !!asset.allowsRecording &&
            !!recordingConfig.enabled &&
            !isLiveEvent(asset)
    );

    const [shouldDisplayRecordButton, setShouldDisplayRecordButton] =
        useState<boolean>(isRecordableAsset);

    // Constants
    const shouldDisplayShareButton =
        screens.details.enableShareButton && (hasPlayableAction || isLiveEvent(asset));

    const shouldDisplayListableButton =
        appSettings?.features.favourites?.enabled &&
        Boolean(
            asset.type !== ASSET_TYPE.CHANNEL &&
                asset.type !== ASSET_TYPE.BROADCAST &&
                asset.type !== ASSET_TYPE.LIVE_EVENT &&
                asset.type !== ASSET_TYPE.EPG &&
                onMyListPress &&
                !!userData
        );
    const shouldDisplayRatingButton =
        !!ratingConfig.enabled && isRateableAsset && Boolean(userData);
    const shouldDisplayReminderButton = Boolean(
        getEpgType(asset) === EPG_TYPE.FUTURE &&
            !!onRemindPress &&
            remindersEnabled &&
            // if broadcast starts in less than 5 mins, don't show set reminder
            typeof asset.start === 'number' &&
            !brodacastStartsInFiveMinsOrLess(asset.start)
    );
    const shouldDisplayRestartButton =
        asset.allowsRestart !== false &&
        isBroadcast(asset) &&
        primaryButtonType === PrimaryButtonType.PLAY &&
        asset?.channel?.features?.live?.options?.startover;

    const shouldDisplayPrimaryButton =
        asset.type !== ASSET_TYPE.EPG || Boolean(getEpgType(asset) !== EPG_TYPE.FUTURE);
    const shouldDisplayEpisodeInfo =
        !!asset.seasonNumber || !!asset.episodeNumber || !!asset.episodeTitle;
    const fetchingButtonData = fetchingRatingInfo && recordingState.loading;
    const profileId = selectedProfile?.id;
    const isLiveBroadcast = asset.type === ASSET_TYPE.BROADCAST && !!asset?.isLive;
    const isAssetInProgress =
        asset.type === ASSET_TYPE.BROADCAST
            ? assetHasProgress(asset) && isLiveBroadcast
            : assetHasProgress(asset);

    const isPodcastSeries = getIsPodcastSeries(asset);
    const episodeToWatch = getEpisodeToWatch(seasons || [], isPodcastSeries ? 'latest' : undefined);

    const onRatingAction = async (userRating?: Rating) => {
        if (userRating) {
            updateUserRating(userRating);
            setRatingModalVisible(false);
        }
    };

    const onRemoveRatingAction = async () => {
        deleteUserRating();
        setRatingModalVisible(false);
    };

    const setRatingModalVisibility = (visibility: boolean) => setRatingModalVisible(visibility);
    const setShareDropdownVisibility = (visibility: boolean) =>
        setisShareDropdownVisible(visibility);

    const getReminders = () => {
        if (shouldDisplayReminderButton && profileId) fetchReminder();
    };

    const onShare = async () => {
        const FQDN = appSettings?.features.socialSharing.baseUrl;

        const { type, id } = asset;
        const link = `${FQDN}/details/${type}/${id}`;
        if (isPlatformWeb) {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link);
                showToast(t('socialSharing.success'));
            } else {
                showToast(t('socialSharing.noAccessToClipboard'));
            }
        }

        if (!isPlatformWeb) {
            try {
                await Share.share({
                    message: link,
                });
            } catch (error: unknown) {
                log(error);
            }
        }
    };

    const getPrimaryButtonType = async () => {
        const hasSeasons = seasons && seasons.length > 0;
        const firstEpisode = isSeries(asset) && hasSeasons ? seasons?.[0].episodes[0] : null;
        const checkedEpisode = episodeToWatch ?? firstEpisode;
        const shouldDisplayPlayButton = isSeries(asset)
            ? hasSeasons && hasPlayableAction
            : hasPlayableAction;

        try {
            const assetBlockers = (await fetchAssetBlockers(asset)) || [];
            const episodeBlockers = checkedEpisode
                ? (await fetchAssetBlockers(checkedEpisode)) || []
                : [];
            const allBlockers = [...assetBlockers, ...episodeBlockers];

            if (allBlockers.find((blocker) => blocker.type === BLOCKING_REASON_TYPES.GEO_TYPE)) {
                setPrimaryButtonType(PrimaryButtonType.UNAVAILABLE);
                return;
            }

            if (
                allBlockers?.find(
                    (blocker) => blocker.type === BLOCKING_REASON_TYPES.REQUIRES_PURCHASE
                )
            ) {
                setPrimaryButtonType(PrimaryButtonType.PURCHASE);
                return;
            }

            if (shouldDisplayPlayButton) {
                setPrimaryButtonType(PrimaryButtonType.PLAY);
                return;
            }

            setPrimaryButtonType(PrimaryButtonType.NONE);
        } catch (err) {
            log(err);
            setPrimaryButtonType(PrimaryButtonType.NONE);
        }
    };

    const getActionButtonTitle = (): string => {
        if (!asset) return '';
        const canUseCommonPlayButton = isMovie(asset) || isSeries(asset) || isEpisode(asset);

        switch (true) {
            case assetHasProgress(asset) && (canUseCommonPlayButton || isPodcastSeries):
                return t('common.continue');

            case isPodcastSeries:
                return t('asset.playback.playLatestEpisodeButton');

            case canUseCommonPlayButton:
                return t('asset.playback.playButton');

            case getEpgType(asset) === 1 && asset?.enableCatchUp:
                if (asset.continueWatchingLastTime) {
                    return t('common.continue');
                }
                return t('asset.playback.playButton');

            case isChannel(asset):
                return t('asset.playback.playButton');

            case isLiveEvent(asset) || isBroadcast(asset):
                return t('asset.playback.watchLiveButton');

            default:
                return '';
        }
    };

    // UseEffects

    useEffect(() => {
        getPrimaryButtonType();
    }, []);

    useEffect(() => {
        getReminders();
    }, [profileId]);

    useEffect(() => {
        if (recordingState.status === RECORDING_STATUS.SCHEDULED) {
            setShouldDisplayRecordButton(true);
        }
        if (isRecordableAsset && !recordingState.loading && recordingState.status === undefined) {
            setShouldDisplayRecordButton(isRecordableAsset);
        }
    }, [recordingState]);

    useEffect(() => {
        if (fetchingRatingInfo) setRatingModalVisible(false);
    }, [fetchingRatingInfo]);

    useRefreshOnFocus(() => {
        getReminders();
        getPrimaryButtonType();
    });

    return {
        hasPlayableAction,
        shouldDisplayRecordButton,
        shouldDisplayRatingButton,
        shouldDisplayRestartButton,
        shouldDisplayShareButton,
        shouldDisplayListableButton,
        shouldDisplayPrimaryButton,
        shouldDisplayReminderButton,
        shouldDisplayEpisodeInfo,
        assetRating,
        fetchingRatingInfo,
        isRatingModalVisible,
        isAssetInProgress,
        episodeToWatch,
        isShareDropdownVisible,
        fetchingButtonData,
        profileId,
        isLiveBroadcast,
        primaryButtonType,
        actionButtonTitle: getActionButtonTitle(),
        onRatingAction,
        onRemoveRatingAction,
        setRatingModalVisibility,
        setShareDropdownVisibility,
        onShare,
        ...props,
    };
};

export { useViewModel };
