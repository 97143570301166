import React from 'react';
import { LayoutChangeEvent } from 'react-native';
import { findAssetByGridItem } from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid/View/utils';
import { Asset, PageSection, ROW_TYPES } from '@24i/nxg-sdk-photon';
import PackshotGrid, {
    GridItem,
    GridProps,
} from '@24i/nxg-sdk-gluons/src/components/containers/PackshotGrid';
import SingleRow from '@24i/nxg-sdk-gluons/src/components/containers/SingleRow';
import { View } from '@24i/nxg-sdk-quarks';
import { SingleRowMobileProps } from '@24i/nxg-sdk-gluons/src/components/containers/SingleRow/View/types';
import { SINGLE_ROW_TEST_IDS } from '@24i/nxg-sdk-gluons/src/components/containers/SingleRow/test-utils';

import BannerCarousel from '../../../../../components/BannerCarousel';
import { ExtendedItem, SliderComponentProps } from '../../../../../components/Slider/types';
import Slider from '../../../../../components/Slider';
import { useStore } from '../../../../../context/ApplicationStore';
import { DynaRowSectionProps } from '../types';
import getDynaRowSectionStyles from '../styles';
import { BannerCarouselTypes } from '../../../../../components/BannerCarousel/types';

export const defaultRenderSingleRow = (props: SingleRowMobileProps) => <SingleRow {...props} />;
export const defaultRenderPackshotGrid = (props: GridProps) => <PackshotGrid {...props} />;
export const defaultRenderSlider = (props: SliderComponentProps) => <Slider {...props} />;
export const defaultRenderBannerCarousel = (props: BannerCarouselTypes) => (
    <BannerCarousel {...props} />
);

const DynaRowSection = (props: DynaRowSectionProps) => {
    const {
        section,
        originFromHere,
        index,
        handlePackshotPress,
        renderBannerCarousel = defaultRenderBannerCarousel,
        renderSingleRow = defaultRenderSingleRow,
        renderSlider = defaultRenderSlider,
        renderPackshotGrid = defaultRenderPackshotGrid,
        handleSeeAllItemPress,
        handleOnLayout,
        styles: getStyles = getDynaRowSectionStyles,
        playPodcastFromDetailPage,
    } = props;
    const { runtimeConfig } = useStore();
    const styles = getStyles();

    const rowItemLimit = runtimeConfig?.app.rows.rowItemLimit ?? undefined;
    const itemLimit = section.itemLimit || rowItemLimit || 15;
    const sectionType =
        section.type === ROW_TYPES.SLIDER &&
        section?.items?.length &&
        section?.additionalItems?.length === 1
            ? ROW_TYPES.HERO_SLIDER
            : section.type;
    const showMetadata = section.metadata?.show ?? true;

    const getTestId = (): string | undefined => `${SINGLE_ROW_TEST_IDS.SINGLE_ROW}_${index}`;

    const onItemPress = ({
        asset,
        action,
    }: {
        asset: Asset;
        action?: PageSection['actionForAllItems'];
    }) => {
        let updatedSection = { ...section };
        let updatedAsset = { ...asset };

        if (sectionType === ROW_TYPES.HERO_SLIDER)
            if (action) {
                updatedSection = {
                    ...section,
                    actionForAllItems: action,
                };
            }
        if (sectionType === ROW_TYPES.GRID)
            updatedAsset = findAssetByGridItem(asset as GridItem, section.items || []);

        handlePackshotPress?.({
            asset: updatedAsset,
            section: updatedSection,
            playPodcastFromDetailPage,
            originFromHere,
        });
    };

    const onItemInfoPress = (asset: ExtendedItem | GridItem | Asset) => {
        onItemPress({ asset, action: 'detail' });
    };

    const onItemPlayPress = (asset: ExtendedItem | GridItem | Asset) => {
        onItemPress({ asset, action: 'player' });
    };

    switch (sectionType) {
        case ROW_TYPES.SLIDER:
            return renderSlider({
                items: section.items || [],
                additionalItems: section.additionalItems || [],
                itemLimit,
                index,
                showMetadata,
                onItemPress: (item, _e) => onItemPress({ asset: item }),
            });
        case ROW_TYPES.HERO_SLIDER:
            return (
                <View style={styles.bannerCarouselContainer}>
                    {renderBannerCarousel({
                        section,
                        index,
                        itemLimit,
                        showMetadata,
                        onItemPress: onItemInfoPress,
                        onItemPlayPress,
                    })}
                </View>
            );
        case ROW_TYPES.GRID:
            return (
                <View style={styles.packshotContainer}>
                    {renderPackshotGrid({
                        items: (section?.items as GridItem[]) || [],
                        displayType: section.display || undefined,
                        title: section.label || undefined,
                        actionForAllItems: section.actionForAllItems,
                        onItemPress,
                        showMetadata,
                        testId: ROW_TYPES.GRID,
                    })}
                </View>
            );
        default:
            return (
                <View onLayout={(e: LayoutChangeEvent) => handleOnLayout?.(e, index, section)}>
                    {renderSingleRow({
                        testId: getTestId(),
                        isHighlighted: !!(
                            section.type &&
                            [ROW_TYPES.SINGLE_HIGHLIGHT, ROW_TYPES.DOUBLE_HIGHLIGHTED_ROW].includes(
                                section.type
                            )
                        ),
                        title: section.label,
                        section,
                        carouselIndex: index,
                        itemLimit,
                        index,
                        showMetadata,
                        onItemPress: (item) => onItemPress({ asset: item }),
                        onSeeAllItemPress: handleSeeAllItemPress,
                    })}
                </View>
            );
    }
};

export default DynaRowSection;
